<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmsubscription.title')">
					<v-layout wrap>
						<v-flex xs12 md7>
							<pui-text-field
								:label="$t('pmsubscription.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md5>
							<pui-text-field
								:label="$t('pmsubscription.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md12>
							<pui-field-set :title="$t('pmsubscription.specs')">
								<v-layout wrap>
									<v-flex xs12 md4>
										<pui-number-field
											v-model="model.price"
											:label="$t('pmsubscription.price')"
											toplabel
											required
											min="0"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md4>
										<pui-number-field
											v-model="model.maxnumbersensors"
											:label="$t('pmsubscription.maxnumbersensors')"
											toplabel
											required
											min="0"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md4>
										<pui-number-field
											v-model="model.maxnumberentities"
											:label="$t('pmsubscription.maxnumberentities')"
											toplabel
											required
											min="0"
										></pui-number-field>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmsubscription.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
						<v-flex xs12 md12>
							<pui-field-set :title="$t('pmsubscription.verticals')">
								<v-layout wrap>
									<pui-multi-select
										id="multiSelectVerticals"
										itemValue="pmverticalsid"
										itemText="name"
										v-model="model.verticals"
										:items="allPmVerticals"
										:itemsToSelect="model.verticals"
									></pui-multi-select>
								</v-layout>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md12>
							<pui-field-set :title="$t('pmsubscription.functionalities')">
								<v-layout wrap>
									<pui-multi-select
										id="multiSelectFunctionality"
										itemValue="pmfunctionalityid"
										itemText="name"
										v-model="model.functionalities"
										:items="allPmFunctionalities"
										:itemsToSelect="model.functionalities"
									></pui-multi-select>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmsubscription.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { search } from '@/api/common';
export default {
	name: 'pmsubscriptionform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmsubscription',
			model: 'pmsubscription',
			allPmVerticals: [],
			allPmFunctionalities: []
		};
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {
		async afterGetData() {
			let body = {
				model: 'pmverticals',
				rows: 999
			};

			let { data } = await search(body);
			this.allPmVerticals = data.data;

			body = {
				model: 'pmfunctionality',
				rows: 999
			};

			data = (await search(body)).data;
			this.allPmFunctionalities = data.data;
		}
	}
};
</script>
